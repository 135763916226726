<template>
    <router-view/>
</template>

<script>

export default {
    name: 'intake',
    modules: [
        // 'userProfile',
        'photoStudio'
    ],
    created() {
        this.$store.dispatch('settings/setCompanyLogo', 'Ototax')
    }
}
</script>
